import React, {MouseEventHandler} from 'react';
import classNames from 'classnames';

import CameraSvg from './assets/js/camera.svg';
import CameraCloseSvg from './assets/js/cameraClose.svg';
import styles from './styles.css';

interface Prams {
  handleClick: MouseEventHandler;
  hover?: boolean;
}

export const CameraButton = ({handleClick, hover}: Prams) => {
  return (
    <span
      className={classNames(styles.cameraButton, hover && styles.hover)}
      data-modal-opener-button={true}
      onClick={handleClick}
    >
      <CameraSvg style={(hover && {display: 'none'}) || {}} />
      <CameraCloseSvg style={(!hover && {display: 'none'}) || {}} />
    </span>
  );
};
