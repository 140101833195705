import {TopStoriesCategory, GeoCountry} from '.';

type AppState = 'home' | 'search';

export interface AppStateWindow extends Window {
  appState?: AppState;
  appSearchQuery?: string | undefined;
  appGeo?: GeoCountry;
  appTab?: TopStoriesCategory;
  startingStoryId?: string;
}

export const getExternalAppState = () => {
  return (window as AppStateWindow).appState;
};

export const setExternalAppState = (
  state: AppState,
  {query, tab, geo}: {query?: string; tab?: string; geo?: string} = {}
) => {
  (window as AppStateWindow).appState = state;
  (window as AppStateWindow).appSearchQuery = query;

  window.postMessage(
    {
      data: {
        geo,
        query,
        state,
        tab
      },
      scope: 'appstate'
    },
    window.location.href
  );
};

export const publishExternalEvent = (eventName: string) => {
  window.postMessage(
    {
      data: {
        eventName
      },
      scope: 'userAction'
    },
    window.location.href
  );
};

export const setExternalAppGeo = (geo: GeoCountry) => {
  (window as AppStateWindow).appGeo = geo;
};

export const setExternalAppTab = (tab: TopStoriesCategory) => {
  (window as AppStateWindow).appTab = tab;
};

export const getExternalAppGeo = () => (window as AppStateWindow).appGeo;

export const getExternalAppTab = () => (window as AppStateWindow).appTab;

export const setStartingStoryId = (storyId: string) => {
  (window as AppStateWindow).startingStoryId = storyId;
};

export const getStartingStoryId = () => (window as AppStateWindow).startingStoryId;
