import React, {useEffect, useState, useRef, ReactElement} from 'react';
import classnames from 'classnames';
import URL from 'url-parse';
import withSizes from 'react-sizes';

import {trackEvent, events} from '../../tracking';
import {GeoCountry, TopStoriesCategory, getCategoryKeyByTabAndGeo} from '../../api';
import {topArticles, topArticlesGroupByArticleId, Result} from '../../api/search';
import {setExternalAppState} from '../../api/externalAppState';
import {LocationResponse} from '../../api/location';
import {isMobileDevice} from '../../utils';
import {setToStorage, CATEGORY_STORAGE_KEY} from '../../storage';
import {ResultItem} from '../ResultItem';
import {Spinner} from '../Spinner';
import {AdSlot} from '../AdSlot';

import styles from './styles.css';
import {LessButton} from './LessButton';
import {MoreButton} from './MoreButton';
import {TrackEventHandler} from './types';
import {MoreModal} from './MoreModal';
import {CameraButton} from './CameraButton';
import {PicturesModal} from './PicturesModal';

const RESULTS_PER_PAGE = 20;
const MPU_STEP = 10;
const MPU_ENABLED = true;
const NUM_SHARE_BUTTONS = 7;

interface CategoryDisplay {
  label: string;
  value: TopStoriesCategory;
}

const categories: CategoryDisplay[] = [
  {label: 'Top Stories', value: 'news'},
  {label: 'Showbiz', value: 'entertainment'},
  {label: 'Most Read', value: 'mostRead'},
  {label: 'Sport', value: 'sport'},
  {label: 'New York', value: 'newYork'}
];

const excludeGroupsWithId = (filterResultId: string) => (result: Result) =>
  result.id !== filterResultId && (!result.more || !result.more.find((moreResult) => moreResult.id === filterResultId));

interface TopStoriesProps {
  onPageChange?: (newPage: number) => void;
  onTabChange?: (newTab: TopStoriesCategory) => void;
  onMoreLikeThis?: (story: Result) => void;
  sourceGeo?: GeoCountry;
  showTabs?: boolean;
  refreshStamp?: number | null;
  debug?: boolean;
  startTab?: TopStoriesCategory;
  layoutBreakpoint?: 'mobile' | 'desktop';
  desktopInMobileView?: boolean;
  overrideFirstStoryId?: string;
  hideFacebook?: boolean;
  userLocation?: LocationResponse;
}

const TopStoriesBase = ({
  onPageChange,
  onTabChange,
  onMoreLikeThis,
  sourceGeo = 'gb',
  showTabs = true,
  refreshStamp = null,
  debug = false,
  startTab,
  desktopInMobileView,
  overrideFirstStoryId,
  layoutBreakpoint,
  hideFacebook = false,
  userLocation
}: TopStoriesProps) => {
  const [results, setResults] = useState<Result[] | null>(null);
  const [overrideFirstStory, setOverrideFirstStory] = useState<Result | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTabState] = useState<TopStoriesCategory>(startTab || 'news');
  const [openedMoreId, setOpenedMoreId] = useState<string | null>(null);
  const [openedPicSetId, setOpenedPicSetId] = useState<string | null>(null);
  const [page, setPageState] = useState(0);
  const [hasExpandedPageOnce, setHasExpandedPageOnce] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLDivElement>(null);
  const previousState = useRef<
    {currentTab: TopStoriesCategory; sourceGeo: GeoCountry; hasExpandedPageOnce: boolean} | undefined
  >();
  const showNYTab =
    ((userLocation && userLocation.Country === 'US' && userLocation.REGION_CODE === 'NY') || startTab === 'newYork') &&
    sourceGeo === 'us';

  const setPage = (newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
    setPageState(newPage);
  };
  const setCurrentTab = (newTab: TopStoriesCategory) => {
    if (onTabChange) {
      onTabChange(newTab);
    }
    setCurrentTabState(newTab);
  };
  const requestStories = ({
    tab,
    geo,
    previousTabKey,
    skipTracking
  }: {
    tab: TopStoriesCategory;
    geo: GeoCountry;
    previousTabKey?: string;
    skipTracking?: boolean;
  }) => {
    let aborted = false;
    (async () => {
      try {
        const tabKey = getCategoryKeyByTabAndGeo(tab, geo);
        // Commenting this out to debug RTA anomaly
        // const isAutoRefresh = tabKey === previousTabKey;
        const isAutoRefresh = false;

        setExternalAppState('home', {
          geo,
          tab
        });

        const response = await topArticles(tabKey, hasExpandedPageOnce);

        // Preference is "last seen"
        setToStorage(CATEGORY_STORAGE_KEY, tab);

        if (!skipTracking) {
          if (isAutoRefresh) {
            trackEvent(events.TOP_STORIES_AUTOREFRESH, {channel: tabKey});
          } else {
            trackEvent(events.TAB_VIEW_EVENT, {
              autoRefresh: tabKey === previousTabKey ? 1 : 0,
              channel: tabKey,
              geo,
              previousTabKey,
              tab
            });
          }
        }

        if (!aborted) {
          setResults(response && response.results);
          setLoading(false);
        }
      } catch (error) {
        if (!aborted) {
          setError(error);
        }
      }
    })().catch();

    return () => {
      aborted = true;
    };
  };
  const requestAndSetOverrideStory = async (id: string) => {
    try {
      const overrideStory = await topArticlesGroupByArticleId(id);

      if (overrideStory) {
        setOverrideFirstStory(overrideStory);
        setOpenedMoreId(overrideStory.url);
      }
    } catch (error) {
      // Ignore
    }
  };

  useEffect(() => {
    if (currentTab === 'newYork' && !showNYTab) {
      setCurrentTab('news');

      return;
    }

    setLoading(true);

    const previousTabKey =
      previousState.current &&
      getCategoryKeyByTabAndGeo(previousState.current.currentTab, previousState.current.sourceGeo);
    const hasChangedTab =
      previousState.current &&
      (previousState.current.currentTab !== currentTab || previousState.current.sourceGeo !== sourceGeo);
    const skipTracking = previousState.current && hasExpandedPageOnce && !previousState.current.hasExpandedPageOnce;

    if (!skipTracking) {
      setOpenedMoreId(null);
      setOpenedPicSetId(null);
      setPage(0);
    }

    const abort = requestStories({tab: currentTab, geo: sourceGeo, previousTabKey, skipTracking});

    previousState.current = {currentTab, sourceGeo, hasExpandedPageOnce};

    if (hasChangedTab) {
      setOverrideFirstStory(null);
    }

    return () => {
      abort();
    };
  }, [currentTab, sourceGeo, refreshStamp, hasExpandedPageOnce]);

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const target = event.target as HTMLElement;

      if (
        target &&
        containerRef.current &&
        (openedMoreId || openedPicSetId) &&
        !containerRef.current.contains(target) &&
        !target.closest(`.${styles.relatedButton}`) &&
        !target.closest('[data-modal-opener-button]')
      ) {
        setOpenedMoreId(null);
        setOpenedPicSetId(null);

        if (openedMoreId) {
          trackEvent(events.TOP_STORIES_RELATED_CLOSE);
        } else if (openedPicSetId) {
          trackEvent(events.TOP_STORIES_PIC_SET_CLOSE);
        }
      }
    };

    document.addEventListener('click', handleDocumentClick);

    if ((openedMoreId || openedPicSetId) && !desktopInMobileView && isMobileDevice() && containerRef.current) {
      const openedElement = containerRef.current.querySelector('[data-is-opened="true"]');

      if (openedElement) {
        openedElement.scrollIntoView();
      }
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [openedMoreId, openedPicSetId]);

  useEffect(() => {
    if (!overrideFirstStoryId) {
      return;
    }

    requestAndSetOverrideStory(overrideFirstStoryId).catch();
  }, []);

  if (!results) {
    return (
      <div className={styles.container}>
        {error && <div className={styles.error}>Error fetching Top Stories</div>}
        {!error && loading && <Spinner />}
      </div>
    );
  }

  let resultsToUse = results;

  if (overrideFirstStory) {
    resultsToUse = [overrideFirstStory].concat(
      results.filter(excludeGroupsWithId(overrideFirstStory.id)).slice(0, results.length - 1)
    );
  }

  const maxPages = hasExpandedPageOnce ? Math.floor(resultsToUse.length / RESULTS_PER_PAGE) : 3;
  const resultsInPage = resultsToUse.slice(0, (page + 1) * RESULTS_PER_PAGE);
  const scrollToTop = () => {
    if (headingRef.current) {
      window.scrollTo(0, headingRef.current.offsetTop - 40);
    }
  };
  const handleClose = () => {
    setPage(0);
    trackEvent(events.TOP_STORIES_PAGINATION_LESS);
    requestAnimationFrame(() => {
      const target = containerRef.current && containerRef.current.children[containerRef.current.children.length - 1];

      if (target) {
        target.scrollIntoView();
      } else {
        scrollToTop();
      }
    });
  };
  const handleNextPage = () => {
    setPage(page + 1);
    trackEvent(events.TOP_STORIES_PAGINATION_MORE);
    setHasExpandedPageOnce(true);
  };
  const handleToggleRelated = (url: string, position: number) => {
    const isOpened = openedMoreId === url;

    if (isOpened) {
      setOpenedMoreId(null);
    } else {
      setOpenedMoreId(url);
      setOpenedPicSetId(null);
    }

    if (isOpened) {
      trackEvent(events.TOP_STORIES_RELATED_CLOSE, {url, position});
    } else {
      trackEvent(events.TOP_STORIES_RELATED_OPEN, {url, position});
    }
  };
  const handleTogglePicSet = (url: string, position: number) => {
    const isOpened = openedPicSetId === url;

    if (isOpened) {
      setOpenedPicSetId(null);
    } else {
      setOpenedPicSetId(url);
      setOpenedMoreId(null);
    }

    if (isOpened) {
      trackEvent(events.TOP_STORIES_PIC_SET_CLOSE, {url, position});
    } else {
      trackEvent(events.TOP_STORIES_PIC_SET_OPEN, {url, position});
    }
  };
  const handleResultTracking: TrackEventHandler = (
    eventName,
    {id, url, title},
    subchannel,
    position,
    relatedPosition,
    parentResult
  ) => {
    const topStoriesKey = getCategoryKeyByTabAndGeo(currentTab, sourceGeo);

    trackEvent(eventName, {
      channel: new URL(url).host,
      id,
      parentTitle: parentResult && parentResult.title,
      position,
      relatedPosition,
      subchannel,
      title,
      topStoriesKey,
      url
    });
  };

  const insertMPUEveryNth = (elements: ReactElement[]) => {
    if (layoutBreakpoint !== 'mobile' || desktopInMobileView || !MPU_ENABLED) {
      return elements;
    }

    const elementsWithAds = elements.slice();

    for (let mpuCount = 0; mpuCount < elements.length / MPU_STEP - 1; mpuCount++) {
      const posToInsert = mpuCount + (mpuCount + 1) * MPU_STEP;

      if (elementsWithAds.length < posToInsert) {
        return elementsWithAds;
      }

      const positionId = `mpu_mobile_topstories_${mpuCount + 1}`;
      const mpuElement = (
        <div className={styles.mpuWrapper} key={positionId}>
          <AdSlot position="mpu_mobile" slotId={positionId} slotType="mpu" />
        </div>
      );

      elementsWithAds.splice(posToInsert, 0, mpuElement);
    }

    return elementsWithAds;
  };

  return (
    <div className={classnames(styles.container, desktopInMobileView && styles.desktopInMobileView)} data-page={page}>
      {showTabs && (
        <div className={classnames(styles.heading)} ref={headingRef}>
          <div className={styles.buttonSelectors} data-current-tab={currentTab}>
            {categories
              .filter(({value}) => value !== 'newYork' || showNYTab)
              .map(({label, value}) => (
                <button
                  className={classnames(styles.tabSelector, currentTab === value && styles.selectedTab)}
                  data-tab-name={value}
                  disabled={loading}
                  key={value}
                  onClick={() => setCurrentTab(value)}
                >
                  {label}
                </button>
              ))}
          </div>
        </div>
      )}
      {!showTabs && <div className={styles.noTabsHeading}>TOP STORIES</div>}
      <div
        className={classnames(styles.results, page < maxPages - 1 && styles.hasMore, loading && styles.loading)}
        ref={containerRef}
      >
        {!results.length && <div className={styles.noResults}>No results found</div>}
        {insertMPUEveryNth(
          resultsInPage.map((result, idx) => {
            const moreIsOpened = Boolean(openedMoreId && openedMoreId === result.url);
            const picSetIsOpened = Boolean(openedPicSetId && openedPicSetId === result.url);
            const nextIsOpened = openedMoreId && resultsInPage[idx + 1] && resultsInPage[idx + 1].url === openedMoreId;
            const hasMore = Boolean(result.more && result.more.length);
            const isManualGroup = result.override && result.expires;
            const isPushedGroup = !result.override && result.expires;
            const isBreaking = result.notification;
            const isNotCovered = !result.coverage;
            const handleToggleScopeRelated = () => handleToggleRelated(result.url, idx + 1);
            const handleToggleScopePicSet = () => handleTogglePicSet(result.url, idx + 1);
            const buttonNode = (
              <React.Fragment>
                {(hasMore &&
                  (moreIsOpened ? (
                    <LessButton handleClick={handleToggleScopeRelated} hover={true} />
                  ) : (
                    <MoreButton
                      handleClick={handleToggleScopeRelated}
                      text={desktopInMobileView ? 'More versions' : 'See more versions'}
                      mobileText="More versions"
                      showText={true}
                    />
                  ))) ||
                  undefined}
                {Boolean(result.groupImages && result.groupImages.length) && (
                  <span className={styles.cameraWrapper}>
                    <CameraButton handleClick={handleToggleScopePicSet} hover={picSetIsOpened} />
                  </span>
                )}
              </React.Fragment>
            );

            return (
              <div
                className={classnames(
                  styles.resultWrapper,
                  moreIsOpened && styles.openedMore,
                  nextIsOpened && styles.nextIsOpened,
                  picSetIsOpened && styles.picSetIsOpened,
                  debug && isManualGroup && styles.manualGroup,
                  debug && isPushedGroup && styles.pushedGroup,
                  debug && isNotCovered && styles.notCovered
                )}
                data-is-opened={moreIsOpened || picSetIsOpened}
                key={idx}
              >
                <ResultItem
                  breaking={isBreaking}
                  onClick={() =>
                    handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_article', idx + 1)
                  }
                  onSiteClick={() =>
                    handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_site', idx + 1)
                  }
                  result={result}
                  showRelated={debug}
                  type="topStory"
                  buttonNode={buttonNode}
                  isGroupWinner={true}
                  desktopInMobileView={desktopInMobileView}
                  useGreyOverlay={desktopInMobileView && picSetIsOpened}
                  showFacebookShare={idx < NUM_SHARE_BUTTONS && !hideFacebook}
                  onShareFacebook={() =>
                    handleResultTracking(
                      events.SOCIAL_SHARE_TOPSTORY_FACEBOOK,
                      result,
                      'topstory_result_article',
                      idx + 1
                    )
                  }
                />
                {moreIsOpened && (
                  <MoreModal
                    position={idx + 1}
                    isBreaking={isBreaking}
                    debug={debug}
                    result={result}
                    handleToggle={handleToggleScopeRelated}
                    handleSwitchToPictures={handleToggleScopePicSet}
                    handleMoreLikeThis={onMoreLikeThis}
                    handleTracking={handleResultTracking}
                    buttonNode={buttonNode}
                    desktopInMobileView={desktopInMobileView}
                    showFacebookShare={idx < NUM_SHARE_BUTTONS && !hideFacebook}
                    onShareFacebook={() =>
                      handleResultTracking(
                        events.SOCIAL_SHARE_TOPSTORY_FACEBOOK,
                        result,
                        'topstory_result_article',
                        idx + 1
                      )
                    }
                  />
                )}
                {picSetIsOpened && (
                  <PicturesModal
                    position={idx + 1}
                    debug={debug}
                    result={result}
                    handleToggle={handleToggleScopePicSet}
                    handleSwitchToRelated={handleToggleScopeRelated}
                    handleMoreLikeThis={onMoreLikeThis}
                    handleTracking={handleResultTracking}
                    buttonNode={buttonNode}
                    showFacebookShare={idx < NUM_SHARE_BUTTONS && !hideFacebook}
                    onShareFacebook={() =>
                      handleResultTracking(
                        events.SOCIAL_SHARE_TOPSTORY_FACEBOOK,
                        result,
                        'topstory_result_article',
                        idx + 1
                      )
                    }
                  />
                )}
                {picSetIsOpened && <div className={styles.picSetBackgroundShadow} />}
              </div>
            );
          })
        )}
      </div>
      {maxPages > 1 && (
        <div className={styles.pagination}>
          {page > 0 && (
            <button className={classnames(styles.paginationButton, styles.minimiseButton)} onClick={handleClose}>
              <div>Minimise</div>
            </button>
          )}
          {page < maxPages - 1 && (
            <button
              className={classnames(styles.paginationButton, styles.nextButton)}
              disabled={loading}
              onClick={handleNextPage}
            >
              <div>See more top stories</div>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const mapSizesToProps = ({width}: {width: number}): {layoutBreakpoint: 'mobile' | 'desktop'} => {
  if (width < 768) {
    return {layoutBreakpoint: 'mobile'};
  }

  return {layoutBreakpoint: 'desktop'};
};

export const TopStories = withSizes<TopStoriesProps, TopStoriesProps>(mapSizesToProps)(TopStoriesBase);
