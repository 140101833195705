import {getExternalAppState} from './externalAppState';

export interface AdSlotsWindow extends Window {
  adPositions?: {[key: string]: string[]};
}

export const mountAd = (slotId: string, position: string) => {
  window.postMessage(
    {
      data: {
        appState: getExternalAppState() || 'home',
        position,
        slotId
      },
      scope: 'adSlotMount'
    },
    window.location.href
  );

  const win = window as AdSlotsWindow;

  if (!win.adPositions) {
    win.adPositions = {};
  }

  if (!win.adPositions[position]) {
    win.adPositions[position] = [];
  }

  if (!win.adPositions[position].includes(slotId)) {
    win.adPositions[position].push(slotId);
  }
};

export const unmountAd = (slotId: string, position: string) => {
  window.postMessage(
    {
      data: {
        appState: getExternalAppState() || 'home',
        position,
        slotId
      },
      scope: 'adSlotUnmount'
    },
    window.location.href
  );

  const win = window as AdSlotsWindow;

  if (win.adPositions && win.adPositions[position] && win.adPositions[position].includes(slotId)) {
    win.adPositions[position] = win.adPositions[position].filter((item) => item !== slotId);
  }
};
