import React, {MouseEventHandler, ReactChild} from 'react';
import classNames from 'classnames';

import {Result} from '../../api/search';
import {events} from '../../tracking';
import {ResultItem} from '../ResultItem';

import styles from './styles.css';
import {TrackEventHandler} from './types';
import {PicturesModalPic} from './PicturesModalPic';
import {ModalFooter} from './components/ModalFooter';

export interface Props {
  position: number;
  isBreaking?: boolean;
  debug: boolean;
  result: Result;
  handleToggle: MouseEventHandler;
  handleSwitchToRelated?: (result: Result) => void;
  handleMoreLikeThis?: (result: Result) => void;
  handleTracking: TrackEventHandler;
  buttonNode?: ReactChild;
  desktopInMobileView?: boolean;
  onShareFacebook?: () => void;
  showFacebookShare?: boolean;
}

export const PicturesModal = (props: Props) => {
  const {
    isBreaking,
    result,
    position,
    debug,
    handleTracking,
    handleMoreLikeThis,
    handleToggle,
    handleSwitchToRelated,
    buttonNode,
    desktopInMobileView,
    showFacebookShare,
    onShareFacebook
  } = props;

  const hasMoreLikeThis = Boolean(result.moreLikeTerms && result.moreLikeTerms.length);

  const forcePicCount: number | false =
    debug && parseInt((document.location.href.match(/forcePicCount=(\d)/) || [])[1], 10);
  const curPicCount = forcePicCount || (result.groupImages && result.groupImages.length);

  return (
    <div className={styles.moreModal}>
      <div className={styles.picSetsWrapper}>
        <ResultItem
          breaking={isBreaking}
          onClick={() => handleTracking(events.PAGE_VIEW_EVENT, result, 'topstory_picset_article', position)}
          onSiteClick={() => handleTracking(events.PAGE_VIEW_EVENT, result, 'topstory_picset_site', position)}
          result={result}
          showRelated={debug}
          type="topStory"
          isMainInModal={true}
          buttonNode={buttonNode}
          useGreyOverlay={true}
          isGroupWinner={true}
          showFacebookShare={showFacebookShare}
          onShareFacebook={onShareFacebook}
        />
        <div
          className={classNames(
            styles.picturesModalContent,
            result.groupImages &&
              // @ts-ignore
              styles[`picNo${curPicCount}`]
          )}
        >
          <div className={styles.picSet}>
            {result.groupImages &&
              result.groupImages.map(({url, articleId}, index) => {
                if (forcePicCount && index >= forcePicCount) {
                  return undefined;
                }

                const article =
                  result.id === articleId ? result : result.more && result.more.find(({id}) => id === articleId);

                return (
                  article && (
                    <PicturesModalPic
                      onClick={() =>
                        handleTracking(
                          events.PAGE_VIEW_EVENT,
                          article,
                          'topstory_picset_image',
                          position,
                          index,
                          result
                        )
                      }
                      key={url}
                      description={article.title}
                      url={url}
                      siteHost={new URL(article.url).hostname}
                      sourceUrl={article.url}
                      siteName={article.siteName}
                      siteIconUrl={article.siteIconUrl}
                    />
                  )
                );
              })}
          </div>
          {hasMoreLikeThis && (
            <ModalFooter
              onClick={handleMoreLikeThis && (() => handleMoreLikeThis(result))}
              onClose={handleToggle}
              desktopInMobileView={desktopInMobileView}
              type="pictures"
            >
              {handleSwitchToRelated && (
                <div
                  className={styles.switchToHeadlines}
                  data-modal-opener-button={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSwitchToRelated(result);
                  }}
                >
                  View article headlines
                </div>
              )}
              {handleMoreLikeThis && <span className={styles.searchForMore}>Search for more on this</span>}
            </ModalFooter>
          )}
        </div>
      </div>
    </div>
  );
};
