import axios from 'axios';

import {getApiHost} from '../api';
import {loadScript} from '../utils';
import {publishExternalEvent} from '../api/externalAppState';
import {getPlatform} from '../api/platform';

import * as events from './events';

export {events};

const SCRIPT_ELEMENT_CLASS = 'RTA2-loader';

const DIRECT_EVENTS = [events.PAGE_VIEW_EVENT, events.TAB_VIEW_EVENT];
const GENERIC_EVENT_NAME = 'page_event';

interface Metadata {
  [key: string]: string | string[] | number | undefined;
}

interface RTAIds {
  user: string;
  created: number;
  page: string;
  visit: string;
}

interface RTAWindow extends Window {
  // tslint:disable no-any
  RTA?: {
    tedEvent: (
      eventName: string,
      metadata?: {action: string; name: string; value: Metadata | string} | Metadata
    ) => void;
    ids: Promise<RTAIds>;
  };
  appEventData?: any[];
  molABTest?: any;
  // tslint:enable no-any
}

interface QueuedEvent {
  eventName: string;
  metadata?: Metadata;
}

let manualReferringDomain: string | undefined;
let manualReferringUrl: string | undefined;

export const setManualReferrer = (referringDomain: string, referringUrl: string) => {
  manualReferringDomain = referringDomain;
  manualReferringUrl = referringUrl;
};

let eventQueue: QueuedEvent[] = [];

export const clearEventQueue = () => {
  eventQueue = [];
};

const buildInternalReferrer = (key: string) =>
  [window.location.protocol, '/' + window.location.host, 'click', key].join('/');

const trackRtaHitForAdobe = async () => {
  const win = window as RTAWindow;

  if (!win.RTA) {
    return;
  }

  const {page: rtaPageId, visit: rtaVisitStr} = await win.RTA.ids;
  const rtaVisitId = rtaVisitStr && rtaVisitStr.split('.')[0];

  if (!win.appEventData) {
    win.appEventData = [];
  }

  win.appEventData.push({
    event: 'rtahits',
    rtaPageId,
    rtaVisitId
  });
};

const sendEventToRTA = (eventName: string, metadata?: Metadata) => {
  const RTA = (window as RTAWindow).RTA;

  if (!RTA || !RTA.tedEvent) {
    throw new Error('RTA not loaded');
  }

  if (DIRECT_EVENTS.includes(eventName)) {
    if (eventName === events.PAGE_VIEW_EVENT) {
      if (metadata) {
        metadata.referrer = buildInternalReferrer((metadata.topStoriesKey as string) || 'search');

        if (metadata.query && typeof metadata.query === 'string') {
          metadata.referrer += '?q=' + encodeURIComponent(metadata.query);
          metadata.tags = metadata.query.trim().split(/\s+/);
        }

        if (metadata.subchannel && typeof metadata.subchannel === 'string') {
          publishExternalEvent(metadata.subchannel);
        }
      } else {
        // tslint:disable-next-line: no-console
        console.warn('Cannot track click to unknown destination');

        return;
      }
    } else if (eventName === events.TAB_VIEW_EVENT) {
      if (metadata && metadata.previousTabKey && metadata.previousTabKey !== metadata.channel) {
        metadata.referrer = buildInternalReferrer(metadata.previousTabKey as string);
      }
      if (metadata && metadata.query && typeof metadata.query === 'string') {
        metadata.tags = metadata.query.trim().split(/\s+/);
      }

      trackRtaHitForAdobe().catch();
    }

    if (metadata) {
      metadata.rp = getPlatform();
    }

    RTA.tedEvent(eventName, metadata);
  } else if (eventName === 'push') {
    RTA.tedEvent('push', metadata);
  } else {
    RTA.tedEvent(GENERIC_EVENT_NAME, {
      action: 'store',
      name: eventName,
      value: metadata || 'event'
    });

    publishExternalEvent(eventName);
  }
};

let adobeEventCount = 0;

export const clearAdobeCount = () => {
  adobeEventCount = 0;
};

const STATUS_PERMISSION_TRANSLATION: {[key: string]: string} = {
  default: 'eligible',
  granted: 'subscribed'
};

const sendEventToAdobe = (eventName: string, metadata?: Metadata) => {
  const win = window as RTAWindow;

  if (!win.appEventData) {
    win.appEventData = [];
  }

  if (eventName === events.TAB_VIEW_EVENT) {
    adobeEventCount++;

    const isFirstEvent = adobeEventCount <= 1;
    const extraPageMeta: Metadata = {};

    if (isFirstEvent && manualReferringDomain && manualReferringUrl) {
      extraPageMeta.referringURL = manualReferringUrl;
      extraPageMeta.referringDomain = manualReferringDomain;
    }

    if (isFirstEvent) {
      try {
        extraPageMeta.notificationStatus =
          STATUS_PERMISSION_TRANSLATION[Notification.permission] || Notification.permission;
      } catch (error) {
        // Ignore
      }
    }

    if (metadata && !metadata.query) {
      const pageInfo: Metadata = {
        pageId: `homepage ${metadata.tab}`,
        pageName: `homepage ${metadata.tab}`,
        pageType: 'homepage',
        selectedGeo: metadata.geo,
        ...(metadata.autoRefresh ? {autoRefresh: metadata.autoRefresh} : {}),
        ...extraPageMeta
      };

      return win.appEventData.push({
        event: isFirstEvent ? 'Page Loaded' : 'Top Story Tab Change',
        page: {
          pageInfo
        }
      });
    }

    if (metadata && metadata.query) {
      const pageInfo: Metadata = {
        pageId: 'search',
        pageName: 'search',
        pageType: 'search',
        searchKeyword: metadata.query,
        ...extraPageMeta
      };

      return win.appEventData.push({
        event: isFirstEvent ? 'Page Loaded' : 'Search',
        page: {
          pageInfo
        }
      });
    }

    return;
  }

  if (eventName === events.PAGE_VIEW_EVENT) {
    return win.appEventData.push({
      event: 'Outbound Click',
      metadata
    });
  }

  if (eventName === 'push') {
    return win.appEventData.push({
      event: 'Web Push Event',
      metadata
    });
  }

  return;
};

export const trackEvent = (eventName: string, metadata?: Metadata) => {
  const RTA = (window as RTAWindow).RTA;

  if (RTA && RTA.tedEvent) {
    sendEventToRTA(eventName, metadata);
  } else {
    eventQueue.push({eventName, metadata});
  }

  sendEventToAdobe(eventName, metadata);
};

const initRtaAB = async () => {
  try {
    // tslint:disable-next-line no-any
    const response = await axios.get<any>(`${getApiHost()}/abIDs`, {timeout: 3000});

    (window as RTAWindow).molABTest = response.data.molABTest;
  } catch (error) {
    // Ignore error as per RTA dev
  }
};

export const initTracking = async ({hosts, script}: {hosts: string; script: string}) => {
  try {
    await Promise.all([
      loadScript(script, {
        class: SCRIPT_ELEMENT_CLASS,
        'data-hosts': hosts
      }),
      initRtaAB()
    ]);

    const RTA = (window as RTAWindow).RTA;

    if (!RTA || !RTA.tedEvent) {
      throw new Error('Error loading RTA');
    }

    for (const queuedEvent of eventQueue) {
      sendEventToRTA(queuedEvent.eventName, queuedEvent.metadata);
    }

    clearEventQueue();
  } catch (error) {
    // TODO: log error
  }
};
