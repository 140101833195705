import axios from 'axios';

import {apiPath, GEO_COUNTRIES, GeoCountry, TopStoriesCategory} from './index';

export interface ApiResult {
  geo: GeoCountry;
  name: string;
  tld: string;
  url: string;
  thumb: string;
  tabOrder?: {
    [key in TopStoriesCategory]?: number;
  };
  orderGB?: number;
  orderUS?: number;
  tabs: TopStoriesCategory[];
}

interface ThumbnailList {
  all: ApiResult[];
  geoTabHash: {[key: string]: ApiResult[]};
  geoHash: {[key in GeoCountry]?: ApiResult[]};
}

type Order = 'ordered' | 'unordered';

let listPromise: Promise<ThumbnailList> | null = null;
let shownThumbnails: string[] = [];

// tslint:disable-next-line:no-any
const addToMap = (map: any, key: string, value: any) => {
  if (!map[key]) {
    map[key] = [];
  }

  map[key].push(value);
};

const getHashKey = (geo: GeoCountry, tab: TopStoriesCategory, order: Order) => [geo, tab, order].join(':');

const listThumbnailedDomains = async (): Promise<ThumbnailList> => {
  const response = await axios.get<ApiResult[]>(`${apiPath('/domainThumbs')}`);
  const geoTabHash: {[key: string]: ApiResult[]} = {};
  const geoHash: {[key in GeoCountry]?: ApiResult[]} = {};
  const items = [...response.data];

  for (const item of response.data) {
    for (const geo of GEO_COUNTRIES) {
      const orderKey = `order${geo.toUpperCase()}` as 'orderGB' | 'orderUS';

      if (typeof item[orderKey] !== 'undefined') {
        const geoItem = items.find((curItem) => curItem.geo === geo && curItem.tld === item.tld);

        if (!geoItem) {
          items.push({
            ...item,
            geo
          });
        }
      }
    }
  }

  for (const item of items) {
    // backward compatibility
    if (!item.tabs) {
      item.tabs = ['news', 'sport', 'entertainment'];

      if (!item.tabOrder) {
        const orderKey = `order${item.geo.toUpperCase()}` as 'orderGB' | 'orderUS';

        if (typeof item[orderKey] !== 'undefined') {
          item.tabOrder = {
            entertainment: item[orderKey],
            news: item[orderKey],
            sport: item[orderKey]
          };
        }
      }
    }

    if (item.tabOrder) {
      for (const tab of Object.keys(item.tabOrder)) {
        addToMap(geoTabHash, getHashKey(item.geo, tab as TopStoriesCategory, 'ordered'), item);
      }
    }

    for (const tab of item.tabs) {
      if (!item.tabOrder || typeof item.tabOrder[tab] === 'undefined') {
        addToMap(geoTabHash, getHashKey(item.geo, tab as TopStoriesCategory, 'unordered'), item);
      }
    }

    addToMap(geoHash, item.geo, item);
  }

  for (const key of Object.keys(geoTabHash)) {
    // @ts-ignore
    const [geo, tab, order] = key.split(':') as [GeoCountry, TopStoriesCategory, 'ordered' | 'unordered'];

    if (order === 'ordered') {
      // tslint:disable-next-line:no-non-null-assertion
      geoTabHash[key].sort((a, b) => a.tabOrder![tab]! - b.tabOrder![tab]!);
    }
  }

  return {
    all: response.data,
    geoHash,
    geoTabHash
  };
};

export const clearShownList = () => {
  shownThumbnails = [];
};

export const clearListPromise = () => {
  listPromise = null;
};

interface GetFreshThumbsOptions {
  sourceGeo?: GeoCountry;
  tab?: TopStoriesCategory;
}

export const getFreshDomainThumbnailUrls = async (
  amount: number,
  {sourceGeo = 'gb', tab = 'news'}: GetFreshThumbsOptions
): Promise<ApiResult[]> => {
  if (!listPromise) {
    listPromise = listThumbnailedDomains();
  }

  const list = await listPromise;
  const filtered: ApiResult[] = [];
  let curShownThumbs: string[] = shownThumbnails;

  const getNotShown = (results: ApiResult[]) => results.filter(({url}) => !curShownThumbs.includes(url));
  const syncCurShownThumbs = () => {
    curShownThumbs = Array.from(new Set([...curShownThumbs, ...filtered.map(({url}) => url)]));
  };

  const curGeos = Array.from(
    new Set([sourceGeo, ...Object.keys(list.geoHash).filter((geo) => geo !== sourceGeo)])
  ) as GeoCountry[];

  for (const geo of curGeos) {
    for (const order of ['ordered', 'unordered']) {
      if (filtered.length >= amount) {
        break;
      }

      filtered.push(...getNotShown(list.geoTabHash[getHashKey(geo, tab, order as Order)] || []));
      syncCurShownThumbs();
    }
  }

  if (filtered.length > amount) {
    filtered.splice(amount - filtered.length);
  }

  shownThumbnails = Array.from(new Set([...shownThumbnails, ...filtered.map(({url}) => url)]));

  if (!filtered.length && tab !== 'news') {
    return getFreshDomainThumbnailUrls(amount, {sourceGeo, tab: 'news'});
  }

  return filtered;
};
