import React, {useState, useEffect, ReactChild} from 'react';
import TimeAgo from 'react-timeago';
import classnames from 'classnames';

import {Result, getSiteUrlFromItemUrl} from '../../api/search';
import {shareFacebook} from '../../api/social';
import {boldify} from '../../utils';

import styles from './styles.css';

interface ResultItemProps {
  breaking?: boolean;
  onClick?: () => void;
  onSiteClick?: () => void;
  onShareFacebook?: () => void;
  result: Result;
  showRelated?: boolean;
  type?: 'searchResult' | 'topStory';
  buttonNode?: ReactChild;
  isSmall?: boolean;
  isMainInModal?: boolean;
  useGreyOverlay?: boolean;
  isGroupWinner?: boolean;
  desktopInMobileView?: boolean;
  showFacebookShare?: boolean;
}

export const ResultItem = ({
  onClick,
  onSiteClick,
  result,
  type = 'searchResult',
  breaking = false,
  showRelated = false,
  buttonNode,
  isSmall,
  useGreyOverlay,
  isMainInModal = false,
  isGroupWinner,
  desktopInMobileView,
  showFacebookShare,
  onShareFacebook
}: ResultItemProps) => {
  const {clickUrl, url, title, resultThumbUrl, related, timestamp, siteIconUrl, siteName, paywall, id} = result;
  const siteUrl = getSiteUrlFromItemUrl(url);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [result]);

  const handleImageError = () => {
    requestAnimationFrame(() => setImageError(true));
  };

  const shareTopStoryOnFacebook = () => {
    shareFacebook(`/story/${encodeURIComponent(id)}`, 'facebook_share_topstory');

    if (onShareFacebook) {
      onShareFacebook();
    }
  };

  return (
    <div
      className={classnames(
        styles.result,
        styles[type],
        breaking && styles.breaking,
        showRelated && related && styles.related,
        isSmall && styles.isSmall,
        isMainInModal && styles.isMainInModal,
        isGroupWinner && styles.isGroupWinner,
        desktopInMobileView && styles.desktopInMobileView,
        paywall && styles.isPaywalled
      )}
    >
      {resultThumbUrl && (
        <div
          className={classnames(styles.thumbContainer, imageError && styles.imageError)}
          style={imageError ? {backgroundImage: `url(${siteIconUrl ? siteIconUrl : '/favicon.ico'})`} : {}}
        >
          <a onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
            <img alt="thumbnail" key={resultThumbUrl} onError={handleImageError} src={resultThumbUrl} />
            {paywall && <div className={styles.paywallFlag}>PAYWALL</div>}
            {breaking && <div className={styles.breakingLabel}>BREAKING</div>}
          </a>
        </div>
      )}
      <div className={styles.info}>
        <h2 className={styles.title}>
          <a onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
            {boldify(title)}
          </a>
        </h2>
        {type !== 'topStory' && (
          <a onClick={onClick} className={styles.url} rel="noopener" href={clickUrl} target="_blank">
            {url}
          </a>
        )}
        <div className={styles.snippet}>
          {breaking && (
            <a className={styles.breakingLabel} onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
              BREAKING
            </a>
          )}
          {siteName && (
            <a
              className={classnames(styles.siteLink, useGreyOverlay && styles.grey)}
              href={siteUrl}
              onClick={onSiteClick}
              target="_blank"
              rel="noopener nofollow"
            >
              {siteIconUrl && <img alt="site icon" className={styles.favicon} src={siteIconUrl} />}
              <div className={styles.content}>
                <span>{siteName}</span>
                {timestamp && <span className={styles.snippetSeparator}>·</span>}
                {timestamp && <TimeAgo date={timestamp} />}
              </div>
            </a>
          )}
          {buttonNode}
          {showFacebookShare && (
            <button className={styles.facebookShare} onClick={shareTopStoryOnFacebook} aria-label="Share on Facebook" />
          )}
        </div>
      </div>
    </div>
  );
};
