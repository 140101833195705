import {GeoCountry} from './index';

let baseShareUrl = window.location.origin;

export const setBaseShareUrl = (newUrl: string) => {
  baseShareUrl = newUrl;
};

const twitterByGeo = {
  gb: 'newzitUK',
  us: 'newzitUSA'
};

const facebookPageByGeo = {
  gb: 'https://www.facebook.com/NewzitUK',
  us: 'https://www.facebook.com/NewzitUS'
};

const facebookAppId = '780254395750894';
const getFacebookShareUrl = (url?: string, ito?: string) =>
  `https://www.facebook.com/dialog/feed?app_id=${facebookAppId}&link=${encodeURIComponent(
    `${baseShareUrl}${url || ''}?ito=${ito || 'facebook_share'}`
  )}`;

const getTwitterFollowUrl = (geo: GeoCountry) =>
  `https://twitter.com/intent/follow?original_referer=${encodeURIComponent(
    baseShareUrl
  )}&ref_src=twsrc%5Etfw&region=follow_link&screen_name=${twitterByGeo[geo]}&tw_p=followbutton`;

const getTwitterShareUrl = () =>
  `https://twitter.com/intent/tweet?url=${encodeURIComponent(`${baseShareUrl}?ito=twitter_share`)}`;

const getWindowDimensions = (width: number, height: number) =>
  [
    'resizable=1',
    'scrollbars=1',
    'status=1',
    `width=${width}`,
    `height=${height}`,
    `top=${(window.outerHeight - height) / 2}`,
    `left=${(window.outerWidth - width) / 2}`
  ].join(',');

const openWindowWithSize = (url: string, width: number, height: number) =>
  window.open(url, 'newzit_social', getWindowDimensions(width, height));

export const followFacebook = (geo: GeoCountry) => window.open(facebookPageByGeo[geo]);

export const followTwitter = (geo: GeoCountry) => openWindowWithSize(getTwitterFollowUrl(geo), 800, 650);

export const shareFacebook = (url?: string, ito?: string) =>
  openWindowWithSize(getFacebookShareUrl(url, ito), 530, 630);

export const shareTwitter = () => openWindowWithSize(getTwitterShareUrl(), 500, 300);
