import React, {MouseEventHandler} from 'react';

import styles from './styles.css';

export interface Props {
  description: string;
  url: string;
  siteHost: string;
  sourceUrl: string;
  siteName?: string;
  siteIconUrl?: string;
  onClick?: MouseEventHandler;
}

export const PicturesModalPic = (props: Props) => {
  const {description, url, sourceUrl, siteName, siteIconUrl, onClick} = props;

  return (
    <a className={styles.picSetModalPic} target="_blank" href={sourceUrl} onClick={onClick}>
      <div className={styles.img} style={{backgroundImage: `url(${url})`}} />
      <div className={styles.picSetModalPicText}>
        <div className={styles.description}>{description}</div>
        {siteName && (
          <div className={styles.favicon}>
            {siteIconUrl && <img alt="site icon" src={siteIconUrl} />}
            {siteName}
          </div>
        )}
      </div>
    </a>
  );
};
