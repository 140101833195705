import {getCategoryKeyByTabAndGeo} from '../api';
import {getExternalAppTab, getExternalAppGeo} from '../api/externalAppState';
import {getPlatform} from '../api/platform';

import {buildRTATrackMacro} from './macros/buildRTATrackMacro';
import {getVisitorId} from './getVisitorId';

export const getOptions = async (baseEndpoint: string) => {
  const rtaTrackMacros = await buildRTATrackMacro();
  const visitorId = await getVisitorId();
  const visitorIds = visitorId ? [visitorId] : [];
  const platform = getPlatform();
  const appTab = getExternalAppTab();
  const appGeo = getExternalAppGeo();
  const channel = appTab && appGeo ? getCategoryKeyByTabAndGeo(appTab, appGeo) : null;

  // TODO: Uncomment whenever they want to enable GDPR
  // const profilingAllowed = await isProfilingAllowed();
  const profilingAllowed = true;

  const opts = {
    baseEndpoint,
    metadata: {
      channels: channel ? [channel] : [],
      organisation: 'newzit',
      platform,
      profilingAllowed,
      userAgent: navigator.userAgent,
      visitorIds
    },
    notificationMacros: {
      impression: [...rtaTrackMacros]
    },
    promptFrequency: '7d',
    swUrl: `/sw.js`
  };

  return opts;
};
