import React, {MouseEventHandler} from 'react';
import classnames from 'classnames';

import MinusCircleSvg from './assets/js/minusCircle.svg';
import styles from './styles.css';

interface Prams {
  handleClick: MouseEventHandler;
  text?: string;
  ariaLabel?: string;
  showText?: boolean;
  hover?: boolean;
}

export const LessButton = ({handleClick, text, ariaLabel, showText, hover}: Prams) => {
  return (
    <button
      aria-label={ariaLabel}
      data-modal-opener-button
      className={classnames(styles.relatedButton, hover && styles.hover, showText && styles.showText)}
      onClick={handleClick}
    >
      {text}
      <MinusCircleSvg />
    </button>
  );
};
