import React, {FunctionComponent, MouseEventHandler} from 'react';
import classNames from 'classnames';

import {LessButton} from '../../LessButton';

import styles from './styles.css';

interface Props {
  multi?: boolean;
  onClick?: MouseEventHandler;
  style?: object;
  onClose?: MouseEventHandler | false;
  desktopInMobileView?: boolean;
  type: 'topStories' | 'pictures';
}

export const ModalFooter: FunctionComponent<Props> = ({
  onClick,
  style,
  children,
  onClose,
  multi,
  desktopInMobileView
}) => (
  <div
    className={classNames(styles.modalFooter, multi && styles.multi, desktopInMobileView && styles.desktopInMobileView)}
    onClick={onClick}
    style={style}
  >
    {children}
    {onClose && (
      <LessButton
        handleClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
          onClose(e);
        }}
        ariaLabel="show related"
        hover={true}
      />
    )}
  </div>
);
