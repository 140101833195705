import React from 'react';
import {render} from 'react-dom';
import URL from 'url-parse';
import Cookies from 'js-cookie';

// tslint:disable-next-line: no-import-side-effect
import './footerPages/footerStyles';
import {App} from './components/App';
import {setApiHost, getApiHost, TopStoriesCategory} from './api';
import {setBaseShareUrl} from './api/social';
import {loadIfNeeded} from './gdpr';
import {initTracking, setManualReferrer} from './tracking';
import {getCategoryFromStorage, setABToStorage} from './storage';
import {initWebPush} from './webPush';
import {setStartingStoryId} from './api/externalAppState';

const element = document.getElementById('app-root');

const isValidStartTab = (tab: string | undefined) =>
  tab && ['entertainment', 'sport', 'mostRead', 'newYork'].includes(tab);

const REFERRER_COOKIE = 'X-REF';
const setReferrerCookie = () => {
  try {
    if (document.referrer) {
      Cookies.set(REFERRER_COOKIE, document.referrer);
    } else {
      Cookies.remove(REFERRER_COOKIE);
    }
  } catch (error) {
    // Ignore cookie errors
  }
};

if (element) {
  const parsedUrl = new URL(window.location.href, true);
  let apiHost = element.dataset.apiHost;
  const showTabs = element.dataset.showTabs === 'true';
  const hideFacebook = element.dataset.hideFacebook === 'true';

  if (element.dataset.environment !== 'production' && parsedUrl.query && parsedUrl.query.debugApiHost) {
    apiHost = parsedUrl.query && parsedUrl.query.debugApiHost;
  }

  if (apiHost) {
    setApiHost(apiHost);
  } else {
    throw new Error('No api host set');
  }

  if (element.dataset.absoluteUrl) {
    setBaseShareUrl(element.dataset.absoluteUrl);
  }

  let homeRefreshInterval = element.dataset.homeRefreshInterval
    ? parseInt(element.dataset.homeRefreshInterval, 10)
    : null;

  if (parsedUrl.query && parsedUrl.query.debugHomeRefreshInterval) {
    homeRefreshInterval = parseInt(parsedUrl.query.debugHomeRefreshInterval, 10);
  }

  let startTab: TopStoriesCategory | undefined = getCategoryFromStorage() || undefined;

  if (parsedUrl.query && isValidStartTab(parsedUrl.query.startTab)) {
    startTab = parsedUrl.query.startTab as TopStoriesCategory;
  }

  let overrideFirstStoryId: string | undefined;

  if (parsedUrl.query && parsedUrl.query.storyId) {
    const params = parsedUrl.query;

    overrideFirstStoryId = params.storyId;
    setStartingStoryId(parsedUrl.query.storyId);
    delete params.storyId;
    parsedUrl.set('query', params);
    window.history.replaceState({}, document.title, parsedUrl.href);
  }

  if (parsedUrl.query && parsedUrl.query.referringDomain && parsedUrl.query.referringURL) {
    const params = parsedUrl.query;

    setManualReferrer(parsedUrl.query.referringDomain, parsedUrl.query.referringURL);
    delete params.referringDomain;
    delete params.referringURL;
    parsedUrl.set('query', params);
    window.history.replaceState({}, document.title, parsedUrl.href);
  }

  const abValue = setABToStorage(parsedUrl.query && parsedUrl.query.debugABNumber);

  if (abValue) {
    setApiHost(`${getApiHost()}/${abValue}`);
  }

  setReferrerCookie();

  render(
    <App
      startingWithQuery={Boolean(parsedUrl.query && parsedUrl.query.q)}
      showTabs={showTabs}
      startTab={startTab}
      homeRefreshInterval={homeRefreshInterval}
      overrideFirstStoryId={overrideFirstStoryId}
      hideFacebook={hideFacebook}
    />,
    element
  );

  loadIfNeeded().catch();

  const {rtaHosts, rtaScript} = element.dataset;

  if (rtaHosts && rtaScript) {
    initTracking({
      hosts: rtaHosts,
      script: rtaScript
    })
      .then(() => {
        initWebPush().catch();
      })
      .catch();
  }
}
