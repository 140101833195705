import React, {MouseEventHandler, MouseEvent, ReactChild} from 'react';

import {Result} from '../../api/search';
import {events} from '../../tracking';
import {ResultItem} from '../ResultItem';

import styles from './styles.css';
import {TrackEventHandler} from './types';
import {CameraButton} from './CameraButton';
import {ModalFooter} from './components/ModalFooter';

export interface Props {
  position: number;
  isBreaking?: boolean;
  debug: boolean;
  result: Result;
  handleToggle: MouseEventHandler;
  handleMoreLikeThis?: (result: Result) => void;
  handleSwitchToPictures?: (result: Result) => void;
  handleTracking: TrackEventHandler;
  buttonNode?: ReactChild;
  desktopInMobileView?: boolean;
  onShareFacebook?: () => void;
  showFacebookShare?: boolean;
}

export const MoreModal = (props: Props) => {
  const {
    isBreaking,
    result,
    position,
    debug,
    handleToggle,
    handleSwitchToPictures,
    handleMoreLikeThis,
    handleTracking,
    buttonNode,
    desktopInMobileView,
    showFacebookShare,
    onShareFacebook
  } = props;

  const hasMore = Boolean(result.more && result.more.length);
  const hasMoreLikeThis = Boolean(result.moreLikeTerms && result.moreLikeTerms.length);
  const handleSwitchToPicturesClick = (event: MouseEvent) => {
    event.stopPropagation();

    if (handleSwitchToPictures) {
      handleSwitchToPictures(result);
    }
  };

  // {hasMoreLikeThis && (
  //   <div className={styles.moreLikeThis} onClick={onMoreLikeThis && (() => onMoreLikeThis(result))}>
  //     {onMoreLikeThis !== undefined && <span>Search for more on this</span>}
  //     {hasMore && isOpened && (
  //       <LessButton
  //         handleClick={(e: React.MouseEvent) => {
  //           e.stopPropagation();
  //           e.preventDefault();
  //           handleToggle();
  //         }}
  //         ariaLabel="show related"
  //       />
  //     )}
  //   </div>
  // )}

  return (
    <div className={styles.moreModal}>
      <div className={styles.relatedStoriesWrapper}>
        <ResultItem
          breaking={isBreaking}
          onClick={() => handleTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_article', position)}
          onSiteClick={() => handleTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_site', position)}
          result={result}
          showRelated={debug}
          type="topStory"
          buttonNode={buttonNode}
          isMainInModal={true}
          isGroupWinner={true}
          desktopInMobileView={desktopInMobileView}
          showFacebookShare={showFacebookShare}
          onShareFacebook={onShareFacebook}
        />
        {hasMore &&
          result.more!.map((moreResult, moreIdx: number) => (
            <ResultItem
              key={moreIdx}
              onClick={() =>
                handleTracking(
                  events.PAGE_VIEW_EVENT,
                  moreResult,
                  'topstory_result_article',
                  position,
                  moreIdx + 1,
                  result
                )
              }
              onSiteClick={() =>
                handleTracking(
                  events.PAGE_VIEW_EVENT,
                  moreResult,
                  'topstory_result_site',
                  position,
                  moreIdx + 1,
                  result
                )
              }
              result={moreResult}
              showRelated={debug}
              type="topStory"
              isSmall={true}
              desktopInMobileView={desktopInMobileView}
            />
          ))}
      </div>
      {hasMoreLikeThis && (
        <ModalFooter
          onClick={handleMoreLikeThis && (() => handleMoreLikeThis(result))}
          onClose={hasMore && handleToggle}
          desktopInMobileView={desktopInMobileView}
          type="topStories"
        >
          {handleSwitchToPictures && (
            <div className={styles.switchToPicturesWrapper}>
              <CameraButton handleClick={handleSwitchToPicturesClick} />
              <button
                className={styles.switchToPicturesTextButton}
                data-modal-opener-button={true}
                onClick={handleSwitchToPicturesClick}
              >
                View image gallery
              </button>
            </div>
          )}
          {handleMoreLikeThis && <span className={styles.searchForMore}>Search for more on this</span>}
        </ModalFooter>
      )}
    </div>
  );
};
