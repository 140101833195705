/* tslint:disable: no-any no-console */
export const getVisitorIdFromAdobe = () => {
  try {
    return (window as any).s.marketingCloudVisitorID as string;
  } catch (error) {
    return null;
  }
};

const MAX_TRIES = 5;
const DELAY_BETWEEN_TRIES = 500;

const delay = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getVisitorId = async () => {
  try {
    for (let tries = 0; tries < MAX_TRIES; tries++) {
      const visitorId = getVisitorIdFromAdobe();

      if (visitorId) {
        return visitorId;
      }

      await delay(DELAY_BETWEEN_TRIES);
    }

    return '';
  } catch (error) {
    console.error('Error getting adobe visitor ID for webpush', error);

    return '';
  }
};
