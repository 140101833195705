import axios from 'axios';
import truncate from 'truncate';

import {apiPath, GeoCountry} from './index';

export interface BaseResult {
  id: string;
  clickUrl: string;
  url: string;
  title: string;
  body: string;
  siteIconUrl?: string;
  siteName?: string;
  thumbnail?: string;
  resultThumbUrl?: string;
  timestamp: string;
  cursor: string;
}

export interface ArticleImage {
  url: string;
  sourceUrl: string;
  articleId: string;
  urlResizedOnly: string;
}

export interface Result extends BaseResult {
  more?: BaseResult[];
  moreLikeTerms?: string[];
  override?: boolean;
  expires?: string | null;
  notification?: boolean;
  coverage?: boolean;
  articleImages?: ArticleImage[];
  groupImages?: ArticleImage[];
  related?: boolean;
  paywall?: boolean;
}

interface SearchResponse {
  results: Result[];
  suggestion?: string;
  betterSuggestion?: string;
  endOfResults?: boolean;
}

interface SearchParams {
  terms?: string;
  after?: string;
  sourceGeo?: string | string[];
  categories?: string | string[];
}

interface SearchOptions {
  categories?: string | string[];
  lastCursor?: string;
  sourceGeo?: string;
}

export const getSiteUrlFromItemUrl = (url: string) => url.replace(/^(https?:\/\/[^/]+).*/i, '$1');

const mapSearchResult = (resultFromApi: Result) => ({
  ...resultFromApi,
  body: truncate(resultFromApi.body, 300)
});
const getParamsFromOptions = (options: SearchOptions = {}) => {
  const params: SearchParams = {};

  if (options.lastCursor) {
    params.after = options.lastCursor;
  }

  if (options.categories) {
    params.categories = options.categories;
  }

  return params;
};

export const search = async (query: string, options: SearchOptions = {}): Promise<SearchResponse | null> => {
  const params = getParamsFromOptions(options);

  if (query) {
    params.terms = query;
  }

  if (options.sourceGeo) {
    params.sourceGeo = [options.sourceGeo];
  }

  const response = await axios.post<SearchResponse>(apiPath('/search'), params);

  if (!response.data || !response.data.results) {
    return null;
  }

  return {
    betterSuggestion: response.data.betterSuggestion || undefined,
    endOfResults: response.data.endOfResults || undefined,
    results: response.data.results.map(mapSearchResult),
    suggestion: response.data.suggestion || undefined
  };
};

const LIMIT_FIRST_PAGE_ONLY = 20;

export const topArticles = async (categoryGeoKey: string, expanded?: boolean): Promise<SearchResponse | null> => {
  const params: {key: string; limit?: number} = {key: categoryGeoKey};

  if (!expanded) {
    params.limit = LIMIT_FIRST_PAGE_ONLY;
  }

  const response = await axios.post<SearchResponse>(apiPath('/topArticles'), params);

  if (!response.data || !response.data.results) {
    return null;
  }

  return {
    results: response.data.results.map(mapSearchResult)
  };
};

export const autocomplete = async (query: string): Promise<string[] | null> => {
  const params = {
    prefix: query
  };
  const response = await axios.post<string[]>(apiPath('/autocomplete'), params);

  if (!response.data || !response.data.length) {
    return null;
  }

  return Array.from(new Set(response.data.map((term) => term.trim())));
};

export const popularSearches = async (geo: GeoCountry): Promise<string[] | null> => {
  const params = {geo};
  const response = await axios.post<{results: string[]}>(apiPath('/ticker'), params);

  if (!response.data.results || !response.data.results.length) {
    return null;
  }

  return response.data.results;
};

export const topArticlesGroupByArticleId = async (id: string): Promise<Result | null> => {
  const params = {id};

  const response = await axios.post<Result>(apiPath('/topArticleGroup'), params);

  return response.data || null;
};
