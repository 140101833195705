import React, {useState, useEffect} from 'react';

import {getVisitorIdFromAdobe} from '../../webPush/getVisitorId';

import styles from './styles.css';

export const DebugInfo = () => {
  const [webPushEndpoint, setWebPushEndpoint] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const visitorId = getVisitorIdFromAdobe();

  const setAsyncData = async () => {
    try {
      setError(null);
      setLoading(true);

      const subscription = await (await navigator.serviceWorker.ready).pushManager.getSubscription();
      const endpoint = subscription && subscription.endpoint;

      setWebPushEndpoint(endpoint);

      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const handleTextareaClick = (event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => {
    const textarea = event.target as HTMLTextAreaElement;

    textarea.focus();
    textarea.select();
  };

  useEffect(() => {
    setAsyncData().catch();
  }, []);

  return (
    <div className={styles.debugInfo}>
      <div className={styles.heading}>DEBUG INFORMATION</div>

      {error && <div className={styles.error}>{error.toString()}</div>}

      <div className={styles.dataItem}>
        <div className={styles.label}>WEB PUSH ENDPOINT</div>
        <textarea
          onClick={handleTextareaClick}
          className={styles.value}
          readOnly
          value={loading ? 'LOADING...' : webPushEndpoint || 'N/A'}
        ></textarea>
      </div>

      <div className={styles.dataItem}>
        <div className={styles.label}>WEB PUSH VISITOR ID</div>
        <textarea onClick={handleTextareaClick} className={styles.value} readOnly value={visitorId || 'N/A'}></textarea>
      </div>
    </div>
  );
};
