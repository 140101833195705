import {getStartingStoryId} from '../api/externalAppState';
import {trackEvent} from '../tracking';

import {getHulk} from './getHulk';
import {getOptions} from './getOptions';

const STATUS_PERMISSION_TRANSLATION: {[key: string]: string} = {
  default: 'eligible',
  granted: 'subscribed'
};

export const initWebPush = async () => {
  const baseEndpoint =
    window.location.origin === 'https://www.newzit.com'
      ? 'https://www.newzit.com/api/web-push-notification/'
      : 'https://www.newzitint.com/api/web-push-notification/';

  const hulk = await getHulk(baseEndpoint);

  if (!hulk) {
    return;
  }

  const {Register} = hulk;
  const options = await getOptions(baseEndpoint);

  if (Register.isWebPushSupported()) {
    const instance = new Register(options);

    // tslint:disable no-any no-console
    // attach Event Listeners
    instance.on('error', (event: any) => console.error('Error with webpush', event));
    instance.on('notification_click', (event: any) => {
      const id = getStartingStoryId();

      trackEvent('push', {
        ...event,
        id
      });
    });
    instance.on('prompt_impression', (event: any) => {
      const notificationStatus = STATUS_PERMISSION_TRANSLATION[event.permission] || event.permission;

      trackEvent('push', {
        ...event,
        notificationStatus
      });
    });
    instance.on('prompt_click', (event: any) => {
      const promptPermission = event.permission === 'default' ? 'closed' : event.permission;
      const notificationStatus = STATUS_PERMISSION_TRANSLATION[event.permission] || event.permission;

      trackEvent('push', {
        ...event,
        notificationStatus,
        promptPermission
      });
    });

    await instance.init();
    await instance.showPrompt();
  }
};
